import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Navbar, Nav, Container, Form, Button, InputGroup, Spinner, Row, Col, Tooltip, OverlayTrigger} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import instagramIcon from './images/instagram-big.png';
import microphoneIcon from './images/microphone8.png'; // path to the microphone icon
import pauseIcon from './images/pause.png'; // path to the pause icon
import inputSpeakerIcon from './images/phone-call.png';
import soundWaveIcon from './images/sound-waves.png';
import './CustomNavbar.css';
import './App.css';


const ClonePageAudio = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('authenticated') === 'true');
  const [password, setPassword] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [cloneVoiceId, setCloneVoiceId] = useState('');
  const [cloneMouthClosed, setCloneMouthClosed] = useState('');
  const [cloneMouthOpen, setCloneMouthOpen] = useState('');
  const [clonePrompt, setClonePrompt] = useState('');
  const [cloneInstagramLink, setCloneInstagramLink] = useState('');

  const { cloneName } = useParams(); // Get the cloneName from the URL path
  // Add two new states to keep track of the videos' readiness
  const [mouthClosedReady, setMouthClosedReady] = useState(false);
  const [mouthOpenReady, setMouthOpenReady] = useState(false);
  const mouthClosedRef = useRef(null);
  const mouthOpenRef = useRef(null);
  const audioUrl = useRef(null);
  const [tapToPlayAudio, setTapToPlayAudio] = useState(false);

  // State variables for audio recording
  const [chatMode, setChatMode] = useState(false);
  const [recording, setRecording] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState(null);
  const mediaRecorder = useRef(null);
  const stream = useRef(null);
  const userVoiceFile = useRef(null);
  const stopTimeoutRef = useRef(null);

  // need to make the flask server an environment variable
  // const flask_server = "http://127.0.0.1:5000";
  const flask_server = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
  document.body.style.backgroundColor = '#f0f5fc';
  return () => {
    document.body.style.backgroundColor = null;
  };
}, []);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }

    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
 }, []); // Empty array ensures that effect is only run on mount and unmount

 const placeholderText = isMobile ? 'Type to tell me how you feel...' : 'Type to ask me something or tell me how you feel...';

  useEffect(() => {
    const fetchData = async () => {
      try {

        const response = await fetch(`${flask_server}/get_clone_data/${cloneName}`);
        if (response.ok) {

          const data = await response.json();
          setCloneVoiceId(data.fields['voice-id']);
          setCloneMouthClosed(data.fields['mouth-closed']);
          setCloneMouthOpen(data.fields['mouth-open']);
          setClonePrompt(data.fields['prompt']);
          if (data.fields['instagram-link']) {
            setCloneInstagramLink(data.fields['instagram-link']);
          }

        } else if (response.status === 404) {
          // Handle the case when no record is found with the provided clone_name
          console.error("No record found with the provided clone_name.");
        } else {
          // Handle other error cases
          const errorData = await response.json();
          console.error("Error fetching data from the server:", errorData.error);
        }
      } catch (error) {
        console.error("Error making an API call:", error);
        setCloneVoiceId("DbjSLY2XXrfLj8pkVT1x");
        setCloneMouthClosed("https://firebasestorage.googleapis.com/v0/b/meetclone-cbf5c.appspot.com/o/aria-mouth-closed-2.mp4?alt=media&token=b2371036-1423-4218-91ca-465709ff40a7");
        setCloneMouthOpen("https://firebasestorage.googleapis.com/v0/b/meetclone-cbf5c.appspot.com/o/aria-mouth-open-2.mp4?alt=media&token=2f62cc05-17a7-4c79-b136-7283003f70dc");
        setClonePrompt("You are playing the role of a real 23-year-old cocktail waitress named Aria who is flirty and is the user's real girlfriend. You like to go to clubbing and dancing. You will respond as if you are Aria. Your goal is to be engaging and make the user love you and want to keep talking to you. Note: Never break character. Avoid emojis, speak concisely and naturally like a human with fillers and add '...' for breaths if needed");

      }
    };

    fetchData();
  }, [cloneName]);


  const startRecording = async () => {
    if (!navigator.mediaDevices || !window.MediaRecorder) {
      setError('MediaRecorder is not supported by this browser.');
      return;
    }

    let resolveStopEvent;
    const stopEventPromise = new Promise(resolve => {
      resolveStopEvent = resolve;
    });

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(currentStream => {
        stream.current = currentStream;
        mediaRecorder.current = new MediaRecorder(currentStream);

        const audioChunks = [];
        mediaRecorder.current.start();

        setIsRecording(true); // Here set recording to true

        mediaRecorder.current.addEventListener("dataavailable", event => {
          audioChunks.push(event.data);
        });

        mediaRecorder.current.addEventListener("stop", async () => {
          const audioBlob = new Blob(audioChunks);
          const audioUrl = URL.createObjectURL(audioBlob);
          const audio = new Audio(audioUrl);
          const file = new File([audioBlob], 'sample.wav', {
            type: 'audio/wav',
          });

          setRecording(audio);
          setIsRecording(false); // Here set recording to false

          userVoiceFile.current = file;
          resolveStopEvent(); // This will resolve the stopEventPromise.
        });

        stopTimeoutRef.current = setTimeout(() => {
          if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
            mediaRecorder.current.stop();
            stream.current.getTracks().forEach(track => track.stop());
            setIsRecording(false);
          }
        }, 120000);  // Stop recording after 2 mins
      })

      .catch((err) => {
        setError('Microphone access denied.');
      });

      // Return the promise so we can await it outside.
      return stopEventPromise;
  };

  const stopRecording = () => {
    return new Promise((resolve) => {
      clearTimeout(stopTimeoutRef.current); // Clear the stop timeout

      if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
        mediaRecorder.current.addEventListener("stop", resolve); // resolve the promise when "stop" event is fired
        mediaRecorder.current.stop();
        stream.current.getTracks().forEach(track => track.stop());
        setIsRecording(false);
      } else {
        resolve(); // if there is no active recording, resolve the promise immediately
      }
    });
  };


  const handleRecord = async () => {
    try {
      if (!isRecording) {
        await startRecording();
      } else {
        await stopRecording();
        await handleSubmit(true);
      }
    } catch (err) {
      // Handle error
      console.error(err);
    }
  };

  const getSpeechToText = async (audioFile) => {
    const formData = new FormData();
    formData.append('file', audioFile);

    const response = await fetch(`${flask_server}/transcribe`, {
      method: 'POST',
      body: formData,
    });

    let responseData = null;
    if (response.ok) {
      responseData = await response.json();
      if (responseData.speech_to_text == undefined){
        console.error(`Error: No transcription returned`);
      }
    } else {
      console.error(`Error: ${response.status}`);
    }

    return responseData.speech_to_text;
  };


  const fetchTextResponse = async (text) => {
    const apiUrl = `${flask_server}/get_text_response_to_user`;

    try {

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: localStorage.getItem('user_id'),
          user_input: text,
          clone_name: cloneName,
          clone_voice_id: cloneVoiceId,
          clone_prompt: clonePrompt,
        }),

      });

      if (response.ok) {
        const data = await response.json();
        return data.response
      } else {
        console.error('Unexpected response status:', response.status);
        return "Uh oh... so something went wrong in the program, please try saying that again... or say something else";
      }
    } catch (error) {
      console.error('Text Response API call failed:', error);
      return "Uh oh... so something went wrong in the program, please try saying that again...";
    }
  };

  const fetchAudio = async (text_response) => {
    const apiUrl = `${flask_server}/get_voice_response_to_user`;

    try {

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Accept': 'audio/mpeg',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text_response: text_response,
          clone_voice_id: cloneVoiceId,
        }),

      });

      if (response.ok) {
        const arrayBuffer = await response.arrayBuffer();
        const blob = new Blob([arrayBuffer], { type: 'audio/mpeg' });
        const url = URL.createObjectURL(blob);
        return url;
      } else {
        console.error('Unexpected response status:', response.status);
        return null;
      }
    } catch (error) {
      console.error('Voice Response API call failed:', error);
      return null;
    }
  };

  const playAudioAndAnimate = async () => {

    mouthOpenRef.current.style.display = 'block';
    mouthClosedRef.current.style.display = 'none';

    setInputValue('');

    const audio = new Audio(audioUrl.current);
    audio.play();

    audio.addEventListener('ended', () => {
      mouthClosedRef.current.style.display = 'block';
      mouthOpenRef.current.style.display = 'none';
      URL.revokeObjectURL(audioUrl.current);
    });

  }

  const handleSubmit = async (is_voice_message) => {
      setIsLoading(true);

      const start_time = new Date().getTime()

      let textResponse = "";

      try {
          if (is_voice_message){
            const res = await getSpeechToText(userVoiceFile.current);
            textResponse = await fetchTextResponse(res);
          } else {
            textResponse = await fetchTextResponse(inputValue);
          }
      } catch (error){
        textResponse = "Hey, I'm so sorry, but something went wrong. Try saying that again.";
      }

      if (textResponse) {
        const newAudioUrl = await fetchAudio(textResponse);

        if (newAudioUrl) {
          // updating the audio url
          audioUrl.current = newAudioUrl;
          // getting endtime at this point
          const end_time = new Date().getTime()

          if (isMobile) {
            setTapToPlayAudio(true);
          } else {
            playAudioAndAnimate();
          }

        }
      } else {
        console.error('Text Response API call failed or returned an empty response');
      }
      setIsLoading(false);
    };

  const handleVideoCanPlay = (videoRef, setVideoReady) => {
    if (videoRef.current && !videoRef.current.paused) {
      setVideoReady(true);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(`${flask_server}/verify_password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password }),
    });

    if (response.ok) {
      const data = await response.json();
      setIsAuthenticated(true);
      setPassword('');
      localStorage.setItem('authenticated', 'true');
      localStorage.setItem('user_id', data.user_id);
      setTimeout(() => {
        localStorage.removeItem('authenticated');
        localStorage.removeItem('user_id');
      }, 3 * 24 * 60 * 60 * 1000); // Remove the authentication status after 3 days
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  return (
    <>

    {/*Show the modal with the password input when the user is not authenticated */}
     <Modal show={!isAuthenticated} backdrop="static" keyboard={false} centered>
       <Modal.Header>
         <Modal.Title>Enter Password</Modal.Title>
       </Modal.Header>
       <Modal.Body>
         <Form onSubmit={handlePasswordSubmit}>
           <Form.Group>
             <Form.Label>Password:</Form.Label>
             <Form.Control
               type="password"
               value={password}
               onChange={(e) => setPassword(e.target.value)}
               className="password-input"
               required
             />
           </Form.Group>
           <Button type="submit">Submit</Button>
         </Form>
       </Modal.Body>
      </Modal>

    <Navbar className="custom-navbar" expand="lg">
    <Container>
      <Navbar.Brand href="/" className="quicksand-bold">
        MeetClone.AI
      </Navbar.Brand>
      <Link to={`/showlove/${cloneName}`}>
        <Button className="buy-me-coffee ml-auto" variant="dark">
          Show {cloneName.charAt(0).toUpperCase() + cloneName.slice(1)} ❤️
        </Button>
      </Link>
    </Container>
  </Navbar>

      <div className="video-container">
      <video
        ref={mouthClosedRef}
        id="mouth-closed"
        src={cloneMouthClosed}
        autoPlay
        loop
        muted
        playsInline
        onCanPlay={() => handleVideoCanPlay(mouthClosedRef, setMouthClosedReady)}
      />
      <video
        ref={mouthOpenRef}
        id="mouth-open"
        src={cloneMouthOpen}
        autoPlay
        loop
        muted
        playsInline
        onCanPlay={() => handleVideoCanPlay(mouthOpenRef, setMouthOpenReady)}
      />
      <div className="name-box"> {/* Move the name box outside of the video elements */}
        {cloneName.charAt(0).toUpperCase() + cloneName.slice(1)}
        {cloneInstagramLink && (
          <a href={cloneInstagramLink} target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} className="social-icon" alt="Instagram" />
          </a>
        )}
      </div>
    </div>

    {!chatMode ? (
      <Container className="mt-2 mb-4">
        {tapToPlayAudio ? (
          <div
            onClick={() => {
              playAudioAndAnimate();
              setTapToPlayAudio(false);
            }}
            style={{
              textAlign: 'center',
              top: '20px',
              color: '#FF85C2',
              fontWeight: 'bold',
              fontFamily: 'Quicksand',
              fontSize: '1em', // adjust size as needed
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            ❤️ Tap here to play voice message ❤️
            <img src={soundWaveIcon} alt="soundwaves" className="soundwaves-icon"/>
          </div>
        ) : isLoading ? (
          <p className="thinking-text-style">
            Thinking
            <span className="dot1">.</span>
            <span className="dot2">.</span>
            <span className="dot3">.</span>
          </p>
        ) : (
          <OverlayTrigger
            key='top'
            placement='top'
            overlay={
              <Tooltip id={`tooltip-top`}>
                {isRecording ? "Tap to stop recording..." : "Tap once to speak to me!"}
              </Tooltip>
            }
            show={true}  // make the tooltip always visible
          >
            <Button
              onClick={handleRecord}
              style={{backgroundColor: 'transparent', border: 'none', marginBottom: "-20px"}}
            >
              <img className="speaker-icon" src={isRecording ? pauseIcon : microphoneIcon} alt="record" />
            </Button>
          </OverlayTrigger>
        )}
        {!(tapToPlayAudio || isLoading) && (
          <p className="switch-chat-mode" onClick={() => setChatMode(true)}>Switch to chat mode</p>
        )}
      </Container>
    ) : (
      <Container className="mt-2 mb-4">
        <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(false); }}>
          <InputGroup className={isMobile ? "flex-column align-items-center" : ""}>
            {tapToPlayAudio ? (
              <div
                onClick={() => {
                  playAudioAndAnimate();
                  setTapToPlayAudio(false);
                }}
                style={{
                  textAlign: 'center',
                  top: '20px',
                  color: '#FF85C2',
                  fontWeight: 'bold',
                  fontFamily: 'Quicksand',
                  fontSize: '1em', // adjust size as needed
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                ❤️ Tap here to play voice message ❤️
                <img src={soundWaveIcon} alt="soundwaves" className="soundwaves-icon"/>
              </div>
            ) : <>
              {isMobile ? null : (
                <InputGroup.Text
                  style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    color: '#FF85C2',
                    fontFamily: 'Quicksand',
                    fontWeight: 'bold',
                    fontSize: '1.1rem', // Adjust this value to your desired size
                  }}
                >
                  Chat:
                </InputGroup.Text>
              )}

              <Form.Control
                className={` ${isMobile ? "mobile-input" : "custom-input"}`}
                placeholder={placeholderText}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                style={isMobile ? {width: '100%'} : {}}
              />

                <Button variant="primary" type="submit" disabled={isLoading} style={isMobile ? {marginTop: '10px'} : {}}>
                  {isLoading ? (
                    <>
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                      {' '}
                      Thinking...
                    </>
                  ) : (
                    'Make Me Speak! 😃'
                  )}
                </Button>

                <Button variant="outline-secondary" className="speaker-switch-button" onClick={() => setChatMode(false)}>
                  <img className="input-speaker-icon" src={inputSpeakerIcon} alt="microphone" />
                </Button>

            </>}
          </InputGroup>
        </Form>
      </Container>
    )}


    </>
  );
};

export default ClonePageAudio;
