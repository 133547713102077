import React, { useRef, useState, useEffect } from "react";
import RecordRTC from "recordrtc";

const AudioStream = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [transcription, setTranscription] = useState(null);
  const recorderRef = useRef(null);
  const flask_server = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {

    const requestPermissions = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      } catch (err) {
        console.error(err);
      }
    };

    requestPermissions();
  }, []);

  const getSpeechToText = async (audioFile) => {
    const formData = new FormData();
    formData.append("file", audioFile);

    const response = await fetch(`${flask_server}/transcribe`, {
      method: "POST",
      body: formData,
    });

    let responseData = null;
    if (response.ok) {
      responseData = await response.json();
      if (responseData.speech_to_text == undefined) {
        console.error(`Error: No transcription returned`);
      }
    } else {
      console.error(`Error: ${response.status}`);
    }

    return responseData.speech_to_text;
  };

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      recorderRef.current = RecordRTC(stream, { type: "audio", mimeType: "audio/wav"}); // initialize RecordRTC here
      setIsRecording(true);
      recorderRef.current.startRecording();
    } catch (err) {
      console.error(err);
    }
  };

  const handleStopRecording = () => {
    setIsRecording(false);
    recorderRef.current.stopRecording(async () => {
      const blob = recorderRef.current.getBlob();
      const audioFile = new File([blob], "sample.wav", { type: "audio/wav" });
      const text = await getSpeechToText(audioFile);
      setTranscription(text);

      // Clean up - stop the stream when the recording is stopped
      recorderRef.current.stream.getTracks().forEach(track => track.stop());
      recorderRef.current = null;
    });
  };

  return (
    <div>
      <button onClick={isRecording ? handleStopRecording : handleStartRecording}>
        {isRecording ? "Stop Recording" : "Start Recording"}
      </button>
      {isRecording && <p>Recording...</p>}
      {transcription && <p>{transcription}</p>}
    </div>
  );
};

export default AudioStream;
