import React, { useState, useEffect } from 'react';
import { Jumbotron, Container, Button, Table } from 'react-bootstrap';
import NavigationBar from './NavigationBar'; // adjust the path as necessary
import { Link } from 'react-router-dom';
import './App.css';
import './ChatTokensPage.css';
import Card from 'react-bootstrap/Card';


const ShowChatTokens = () => {
    const [tokens, setTokens] = useState(0);
    const [purchaseHistory, setPurchaseHistory] = useState([]);
    const flask_server = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const token = localStorage.getItem('jwt_token');
        if (!token) return; // or handle appropriately

        // Fetch token count
        fetch(`${flask_server}/get_chat_token_count`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setTokens(data.token_count || 0);
        })
        .catch(error => {
            console.error('Error fetching token count:', error);
        });

        // Fetch token purchase history
        fetch(`${flask_server}/get_token_purchase_history`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setPurchaseHistory(data.purchase_history || []);
        })
        .catch(error => {
            console.error('Error fetching purchase history:', error);
        });

    }, []);

    return (
        <>
            <NavigationBar shouldCheckAuthentication={true} />
            <Container className="mt-4 mb-5">
              <Card className="tokens-card">
                  <Card.Body>
                      <Card.Title><h1>{tokens} Chat Tokens Remaining</h1></Card.Title>
                      <Card.Text className="mb-4">
                          Purchase more to continue the conversation!
                      </Card.Text>
                      <Link to="/showlove/shashank">
                          <Button variant="outline-primary">
                              Purchase More Tokens
                          </Button>
                      </Link>
                  </Card.Body>
              </Card>

                <h4>Token Purchase History</h4>
                {purchaseHistory.length ? (
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Clone Name</th>
                                <th>Token Count</th>
                                <th>Price</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {purchaseHistory.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.clone_name}</td>
                                    <td>{item.token_count}</td>
                                    <td>{item.price}</td>
                                    <td>{item.date}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <p>No purchase history available.</p>
                )}
            </Container>
        </>
    );
};

export default ShowChatTokens;
