import { Navbar, Container, Nav, Offcanvas, Modal, Form, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import './App.css';

function NavigationBar({ shouldCheckAuthentication = false }) {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [showLogin, setShowLogin] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);
    const [email, setEmail] = useState("");  // State for email
    const [password, setPassword] = useState("");
    const flask_server = process.env.REACT_APP_BACKEND_URL;

    // Use state to store error messages
    const [loginError, setLoginError] = useState("");
    const [signupError, setSignupError] = useState("");

    useEffect(() => {
        if (shouldCheckAuthentication) {
            checkAuthentication();
        }
    }, []);

    const checkAuthentication = async () => {
        const token = localStorage.getItem('jwt_token');

        if (token) {
            const response = await fetch(`${flask_server}/is_authenticated`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
            }
        } else {
            setIsAuthenticated(false);
        }
    };

    const handleLogout = async () => {
        localStorage.removeItem('jwt_token');
        setIsAuthenticated(false);
        window.location.reload();
    };

    const handleShowLogin = () => setShowLogin(true);
    const handleCloseLogin = () => setShowLogin(false);

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();

        const response = await fetch(`${flask_server}/verify_password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password })
        });

        const data = await response.json();
        if (response.ok) {
            localStorage.setItem('jwt_token', data.access_token);
            setPassword('');
            setIsAuthenticated(true);
        } else {
            setLoginError(data.error || 'Incorrect password. Please try again.');
        }
    };

    const handleSignUpSubmit = async (e) => {
        e.preventDefault();

        const response = await fetch(`${flask_server}/create_user_account`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password })
        });

        const data = await response.json();
        if (response.ok) {
            localStorage.setItem('jwt_token', data.access_token);
            setEmail('');
            setPassword('');
            setIsAuthenticated(true);
            setShowSignUp(false);
        } else {
            setSignupError(data.error || 'Issue with signing up. Try again later');
        }
    };

    return (
        <>
            <Navbar className="custom-navbar" expand="lg">
                <Container>
                    <Navbar.Brand href="/" className="quicksand-bold">MeetClone.AI</Navbar.Brand>
                    <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShowLogin} className="always-show-toggle" />
                </Container>
            </Navbar>

            <Offcanvas show={showLogin} onHide={handleCloseLogin} placement="end" id="offcanvasNavbar">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>MeetClone.AI</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="flex-column">
                    <Nav.Link className="nav-link-bold" href="/shashank">
                      Meet Shashank
                    </Nav.Link>
                    <Nav.Link className="nav-link-bold" href="/showlove/shashank">
                      Show Love ❤️
                    </Nav.Link>
                      <Nav.Link className="nav-link-bold" href="/seetokens">
                        See Token History
                      </Nav.Link>
                        {isAuthenticated ? (
                            <Nav.Link className="nav-link-bold" onClick={handleLogout}>Logout</Nav.Link>
                        ) : (
                            <Nav.Link className="nav-link-bold" href="/shashank">Login</Nav.Link>
                        )}
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>

            <Modal show={isAuthenticated === false && shouldCheckAuthentication} onHide={handleCloseLogin} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                    <Modal.Title>Log In</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handlePasswordSubmit}>
                        <Form.Group>
                          <Form.Label>Email:</Form.Label>
                          <Form.Control
                              type="text"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                          />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password:</Form.Label>
                            <Form.Control
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="password-input"
                                required
                            />
                        </Form.Group>
                        <Button type="submit">Submit</Button>
                        {loginError && <p className="text-danger mt-2">{loginError}</p>}
                    </Form>
                    <div className="mt-3 text-center">
                        Not a member yet? <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={() => {
                            setShowLogin(false);
                            setShowSignUp(true);
                        }}>
                            Sign up
                        </span>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showSignUp} onHide={() => setShowSignUp(false)} centered>
                <Modal.Header>
                    <Modal.Title>Sign Up</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSignUpSubmit}>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <br/>
                        <Button type="submit">Continue 👉</Button>
                        {signupError && <p className="text-danger mt-2">{signupError}</p>}
                    </Form>
                    <div className="mt-3 text-center">
                        Already have an account? <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={() => {
                            setShowSignUp(false);
                        }}>
                            Log in
                        </span>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default NavigationBar;
