import React from 'react';
import NavigationBar from './NavigationBar';
import backgroundImg from './images/black-white-hair-spin.jpg';

const HomePage = () => {
  return (
    <div style={{ margin: 0, padding: 0, overflow: 'hidden', height: '100vh' }}>  {/* Setting overflow and height for outer container */}
      <NavigationBar />
      <div
        style={{
          backgroundColor: '#000',
          backgroundImage: `url(${backgroundImg})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
          height: 'calc(100vh - 60px)',  // Assuming NavigationBar has a height of 60px. Adjust if needed.
        }}
      >
        <h1 style={{
          margin: 0,  // Reset margin
          padding: 0,  // Reset padding
          color: 'white',
          fontWeight: 'bold',
          fontSize: '4vw',  // Responsive font size for various screen widths
          textAlign: 'center',
          padding: '1vw 2vw',
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
        }}>
          Meet AI clones of your favorite celebrities and influencers...
        </h1>
      </div>
    </div>
  );
};

export default HomePage;
