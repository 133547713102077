import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './HomePage';
import ClonePage from './ClonePage';
import ShowLovePage from './ShowLovePage';
import MobileTest from './MobileTest';
import AudioStreamer from './AudioStream';
import AudioTranscribe from './AudioTranscribe';
import ClonePageAudio from './ClonePageAudio';
import ClonePageCombined from './ClonePageCombined';
import PaymentStatusPage from './PaymentStatusPage';
import ShowChatTokens from './ShowChatTokens';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/mobiletest" element={<MobileTest />} />
          <Route path="/audiostream" element={<AudioStreamer />} />
          <Route path="/audiotranscribe" element={<AudioTranscribe />} />
          <Route path="/audioclone/:cloneName" element={<ClonePageAudio />} />
          <Route path="/combinedclone/:cloneName" element={<ClonePageCombined />} />
          <Route path="/showlove/:cloneName" element={<ShowLovePage />} />
          <Route path="/paymentstatus/:clone_name/:token_count/:success" element={<PaymentStatusPage />} />
          <Route path="/:cloneName" element={<ClonePage />} />
          <Route path="/seetokens" element={<ShowChatTokens />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
