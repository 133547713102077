import React, { useState, useEffect, useRef } from 'react';

const MobileTest = () => {
  const [currentVideo, setCurrentVideo] = useState(0);
  const videosRef = useRef([]);

  useEffect(() => {
    const handleVideoEnd = () => {
      setCurrentVideo((prevVideo) => (prevVideo === 0 ? 1 : 0));
    };

    videosRef.current.forEach((video) => {
      video.addEventListener('ended', handleVideoEnd);
    });

    return () => {
      videosRef.current.forEach((video) => {
        video.removeEventListener('ended', handleVideoEnd);
      });
    };
  }, []);

  const videoStyle = (index) => ({
    width: '100%',
    height: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: currentVideo === index ? 1 : 0,
  });

  const videoUrls = [
    'https://firebasestorage.googleapis.com/v0/b/meetclone-cbf5c.appspot.com/o/aria-mouth-closed-2.mp4?alt=media&token=b2371036-1423-4218-91ca-465709ff40a7',
    'https://firebasestorage.googleapis.com/v0/b/meetclone-cbf5c.appspot.com/o/aria-mouth-open-2.mp4?alt=media&token=2f62cc05-17a7-4c79-b136-7283003f70dc',
  ];

  return (
    <div className="video-container" style={{ position: 'relative' }}>
      {videoUrls.map((url, index) => (
        <video
          key={index}
          ref={(el) => (videosRef.current[index] = el)}
          loop
          autoPlay={index === currentVideo}
          muted
          playsInline
          preload="auto"
          style={videoStyle(index)}
          src={url}
        >
          Your browser does not support the video tag.
        </video>
      ))}
    </div>
  );
};

export default MobileTest;
