import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import NavigationBar from './NavigationBar';

function PaymentStatusPage() {
  const { clone_name, token_count, success } = useParams();

  const navigate = useNavigate();

  const isSuccess = success === "true";
  const message = isSuccess
    ? "Success! Payment has been received. Tokens have been added to your account."
    : "Failure! Payment has not been received. Email contact@meetclone.com for us to help you troubleshoot.";

  return (
    <div>
        <NavigationBar />
        <Container className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
          <div style={{ padding: '2rem', background: '#f7f7f7', borderRadius: '0.3rem', width: '100%' }}> {/* This div is an alternative to Jumbotron */}
            <h1 className="mb-4">{isSuccess ? "Success" : "Failure"}</h1>
            <p className="mb-4">{message}</p>
            <Button variant="primary" size="lg" onClick={() => navigate(`/${clone_name}`)}>
              return to {clone_name}'s clone
            </Button>
          </div>
        </Container>
    </div>
  );
}

export default PaymentStatusPage;
