import React, { useState, useRef, useCallback } from 'react';
import { Button } from 'react-bootstrap';

const AudioTranscribe = () => {
  const [recording, setRecording] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState(null);
  const mediaRecorder = useRef(null);
  const stream = useRef(null);

  const flask_server = "http://127.0.0.1:5000";

  const startRecording = useCallback(() => {
    if (!navigator.mediaDevices || !window.MediaRecorder) {
      setError('MediaRecorder is not supported by this browser.');
      return;
    }

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(currentStream => {
        stream.current = currentStream;
        mediaRecorder.current = new MediaRecorder(currentStream);

        const audioChunks = [];
        mediaRecorder.current.start();

        setIsRecording(true); // Here set recording to true

        mediaRecorder.current.addEventListener("dataavailable", event => {
          audioChunks.push(event.data);
        });

        mediaRecorder.current.addEventListener("stop", () => {
          const audioBlob = new Blob(audioChunks);
          const audioUrl = URL.createObjectURL(audioBlob);
          const audio = new Audio(audioUrl);
          const file = new File([audioBlob], 'sample.wav', {
            type: 'audio/wav',
          });

          setRecording(audio);
          setIsRecording(false); // Here set recording to false
          sendToAPI(file);
        });

        setTimeout(() => {
          if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
            mediaRecorder.current.stop();
            stream.current.getTracks().forEach(track => track.stop());
            setIsRecording(false);
          }
        }, 40000);  // Stop recording after 1m30 seconds
      })
      .catch((err) => {
        setError('Microphone access denied.');
      });
  }, []);

  const stopRecording = useCallback(() => {
    if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
      mediaRecorder.current.stop();
      stream.current.getTracks().forEach(track => track.stop());
      setIsRecording(false);
    }
  }, []);

  const sendToAPI = async (audioFile) => {
    const formData = new FormData();
    formData.append('file', audioFile);

    const response = await fetch(`${flask_server}/transcribe`, {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      const responseData = await response.json();
      console.log(responseData);
    } else {
      console.error(`Error: ${response.status}`);
    }
  };

  return (
    <div>
      <Button variant="primary" onClick={startRecording} disabled={mediaRecorder.current && mediaRecorder.current.state !== 'inactive'}>
        Start Recording
      </Button>
      <Button variant="secondary" onClick={stopRecording} disabled={!mediaRecorder.current || mediaRecorder.current.state === 'inactive'}>
        Stop Recording
      </Button>
      <p>{isRecording ? 'Recording...' : 'Not Recording'}</p>
      {error && <p>{error}</p>}
    </div>
  );
}

export default AudioTranscribe;
