import React, { useState, useEffect } from 'react';
import { Navbar, Container, Button, Card, Image } from 'react-bootstrap';
import NavigationBar from './NavigationBar'; // adjust the path as necessary
import { useParams, Link } from 'react-router-dom';
import './App.css';
import './CustomNavbar.css';
import champagneIcon from './images/champagne.png';
import pastaIcon from './images/pasta.png';
import diamondIcon from './images/diamond-ring.png';
import instagramIcon from './images/instagram-big.png';

const ShowLovePage = () => {
  const { cloneName } = useParams();
  const [cloneData, setCloneData] = useState({});
  const [cloneInstagramLink, setCloneInstagramLink] = useState(null);


  const flask_server = process.env.REACT_APP_BACKEND_URL;

  const addTokens = (tokenAmount) => {
      const token = localStorage.getItem('jwt_token');

      fetch(`${flask_server}/add_tokens`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
              token_count: tokenAmount,
              clone_name: cloneName
          })
      })
      .then(response => response.json())
      .then(data => {
          if (data.checkout_url) {
              window.location.href = data.checkout_url;
          } else {
              alert('Something went wrong');
          }
      })
      .catch((error) => {
          alert(`Error: ${error}`);
      });
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${flask_server}/get_clone_data/${cloneName}`);
        if (response.ok) {
          const data = await response.json();
          setCloneData(data.fields);

          // Handle instagram-link
          if (data.fields['instagram-link']) {
            setCloneInstagramLink(data.fields['instagram-link']);
          }
        } else {
          console.error("Error fetching clone data");
        }
      } catch (error) {
        console.error("Error making an API call:", error);
      }
    };

    fetchData();
  }, [cloneName]);

  return (
    <>
      <NavigationBar shouldCheckAuthentication={true} />

      <Container className="text-center mt-4 mb-5">

        <Link to={`/${cloneName}`}>
          <Image src={cloneData["profile-picture"]} roundedCircle className="circular-image mb-2" />
        </Link>
        <h2>
        {cloneName.charAt(0).toUpperCase() + cloneName.slice(1)}
        {cloneInstagramLink &&
          <a href={cloneInstagramLink} target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} className="social-icon-2" alt="Instagram" />
          </a>
        }
        </h2>
        <hr className="w-50 mx-auto mb-4" />

        <h4 className="mb-4">
          Show {cloneName.charAt(0).toUpperCase() + cloneName.slice(1)} some love and appreciation!
        </h4>

        <div className="d-flex flex-column flex-md-row justify-content-center">
          <Card className="m-2">
            <Card.Body>
              <img src={champagneIcon} className="gift-icon" alt="Champagne icon"/>
              <Card.Title>Buy me a drink</Card.Title>
              <Card.Text>
                Get <b>+20</b> response tokens to get to know {cloneName.charAt(0).toUpperCase() + cloneName.slice(1)}
              </Card.Text>
              <Button variant="primary" onClick={() => addTokens(20)}>$6 Donation ❤️</Button>
            </Card.Body>
          </Card>

          <Card className="m-2">
            <Card.Body>
              <img src={pastaIcon} className="gift-icon" alt="Pasta icon"/>
              <Card.Title>Buy me dinner</Card.Title>
              <Card.Text>
                Get <b>+100</b> response tokens to get to know {cloneName.charAt(0).toUpperCase() + cloneName.slice(1)}
              </Card.Text>
              <Button variant="primary" onClick={() => addTokens(100)}>$40 Donation ❤️</Button>
            </Card.Body>
          </Card>

          <Card className="m-2">
            <Card.Body>
              <img src={diamondIcon} className="gift-icon" alt="Diamond icon"/>
              <Card.Title>Buy me a diamond ring</Card.Title>
              <Card.Text>
              Get <b>+1000</b> response tokens to get to know {cloneName.charAt(0).toUpperCase() + cloneName.slice(1)}
              </Card.Text>
              <Button variant="primary" onClick={() => addTokens(1000)}>$400 Donation ❤️</Button>
            </Card.Body>
          </Card>

        </div>

        <h6 className="mt-4">
          Your kindness means the world to me - {cloneName.charAt(0).toUpperCase() + cloneName.slice(1)}
        </h6>
      </Container>
    </>
  );
};

export default ShowLovePage;
